import comptoir_nouveau from '../images/vitrines/comptoir_nouveau.jpg';
import comptoir_sans_caisson from '../images/vitrines/comptoir_sans_caisson.jpg';
import colonne_bijoux_new from '../images/vitrines/colonne_bijoux_new.jpg';
import Grandes_large from '../images/vitrines/Grandes_large.jpg';
import colonne_vitree_new from '../images/vitrines/colonne_vitree_new.jpg';
import stele_vid from '../images/vitrines/stele.webm';
import batterie_vid from '../images/vitrines/vitrine_comptoir.webm';

import minimax_vid from '../images/vitrines/minimax_vid.webm';

export const liste_vitrines = [
  {
	nom: "Grande large",
	type:2,
	description:"<p>Portes coulissantes avec serrure</p><p>4 étagères (ajout sur demande sans frais) facilement réglables grâce à un système de crémaillères et de supports</p><p>Spots orientables</p><p>Pieds réglables</p><p><u>Existe avec caisson</u></p>",
	hauteur:"2m",
	hauteurInterieur:"",
	largeur:"1m",
	profondeur:"50cm",
	lumiere:"<p>Un éclairage 100% LED avec 5 spots plafonniers orientables et 8 spots sur les côtés et un choix températures de couleurs : jaune (2700k), blanc (4000k) ou ultra-blanc (6000k).</p>",
	consommation:"95",
	photos:[Grandes_large],
	prix:"215 €",
	slug:"grande-large",
	mw:"100%",
	legendePhoto:[],
	diapo:false,
  },
  {
	nom: "Colonne bijoux",
	type:2,
	description:"<p>Livrée avec ou sans étagère (ajout sur demande sans frais) facilement réglables grâce à un système de crémaillères et de supports</p><p>Spots orientables</p><p>Portes battantes avec serrure</p><p>Pieds réglables</p>",
	hauteur:"2m",
	hauteurInterieur:"",
	largeur:"60cm",
	profondeur:"60cm",
	lumiere:"<p>Un éclairage 100% LED avec 4 spots plafonniers orientables et un choix températures de couleurs : jaune (2700k), blanc (4000k) ou ultra-blanc (6000k).</p>",
	consommation:"28",
	photos:[colonne_bijoux_new],
	prix:"205 €",
	slug:"colonne-bijoux",
	mw:"100%",
	legendePhoto:[],
	diapo:false,
  },
  {
	nom: "Colonne vitrée",
	type:2,
	description:"<p>4 étagères (ajout sur demande sans frais) facilement réglables grâce à un système de crémaillères et de supports</p><p>Spots orientables</p><p>Porte battante avec serrure</p><p>Pieds réglables</p>",
	hauteur:"2m",
	hauteurInterieur:"",
	largeur:"60cm",
	profondeur:"60cm",
	lumiere:"<p>Un éclairage 100% LED avec 4 spots plafonniers orientables et 8 spots sur les côtés et un choix températures de couleurs : jaune (2700k), blanc (4000k) ou ultra-blanc (6000k).</p>",
	consommation:"84",
	photos:[colonne_vitree_new],
	prix:"205 €",
	slug:"colonne-vitree",
	mw:"350px",
	legendePhoto:[],
	diapo:false,
  },
  {
	nom: "Comptoir",
	type:2,
	description:"<p><u>Disponible avec ou sans caisson</u></p><p>Portes coulissantes avec serrure</p>",
	hauteur:"1m",
	largeur:"1m",
	profondeur:"60cm",
	hauteurInterieur:"32cm",
	lumiere:"<p>Un éclairage par bandeau LED avec choix des température de couleurs par télécommande : jaune (2700k), blanc (4000k) ou ultra-blanc (6000k).</p>",
	consommation:"5",
	photos:[comptoir_nouveau, comptoir_sans_caisson],
	prix:"185 €",
	slug:"comptoir",
	mw:"100%",
	legendePhoto:["Comptoir avec caisson", "Comptoir sans caisson"],
	diapo:true,
  },
  {
	nom: "Comptoir Minimax",
	type:2,
	description:"<p>Le comptoir dédié aux espaces contraints, atypiques, avec peu d’emprise au sol ou en étage, en sous-sol sans monte-charge…</p>",
	hauteur:"1m",
	largeur:"1m",
	profondeur:"50cm",
	hauteurInterieur:"32cm",
	lumiere:"<p>Éclairage par réglette led avec télécommande pour varier l’intensité et la température de couleur de 2700k à 6500 k</p>",
	consommation:"5",
	photos:[minimax_vid],
	prix:"185 €",
	slug:"comptoir-minimax",
	mw:"100%",
	legendePhoto:[],
	diapo:false,
  },
  {
	nom: "Batterie",
	type:2,
	description:"<p>Profitez d'une autonomie allant de 13 à 21 heures, idéale pour des journées complètes d'exposition.</p><p>Recharge rapide en seulement 1 heure pour une utilisation continue sans interruption.</p>",
	hauteur:"",
	largeur:"",
	hauteurInterieur:"",
	profondeur:"",
	lumiere:"",
	consommation:"",
	photos:[batterie_vid],
	prix:"45 €",
	slug:"batterie",
	mw:"100%",
	legendePhoto:[],
	diapo:false,
  },
  {
	nom: "Stèle",
	type:2,
	description:"<p>Idéale pour exposer des objets individuels ou des petites collections.</p><p>Disponible en plusieurs largeurs : 30 cm, 35 cm et 40 cm.</p><p>Conception élégante et discrète, parfaite pour mettre en valeur vos pièces de présentation.</p>",
	hauteur:"1m",
	hauteurInterieur:"",
	largeur:"30,35,40cm",
	profondeur:"--",
	lumiere:"",
	consommation:"",
	photos:[stele_vid],
	prix:"30 €",
	slug:"stele",
	mw:"100%",
	legendePhoto:[],
	diapo:false,
  }
];

export const tel = "+33 (0)6 17 89 85 48";
export const tel_lien = "+33617898548";
export const mail = "contact@lvdv.fr";
export const adr_1 = "37, rue d'Amsterdam";
export const adr_2 = "75008 Paris";

export const INSTAGRAM_ID = "787132";

export const LIEN_INSTAGRAM = "https://www.instagram.com/lesvitrinesdevalerie/?hl=fr";
export const LIEN_FACEBOOK = "https://www.facebook.com/people/Les-Vitrines-de-Val%C3%A9rie/100088530236864/";